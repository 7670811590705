import { render, staticRenderFns } from "./SearchResultCardPerson.vue?vue&type=template&id=70af226e"
import script from "./SearchResultCardPerson.vue?vue&type=script&lang=js"
export * from "./SearchResultCardPerson.vue?vue&type=script&lang=js"
import style0 from "./SearchResultCardPerson.vue?vue&type=style&index=0&id=70af226e&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PersonLink: require('/app/components/EntityLink/components/PersonLink.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,KeyValuePairContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePairContainerRow.vue').default,EntityCard: require('/app/components/EntityCard/components/EntityCard.vue').default})
