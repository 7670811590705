// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_foHZY{display:flex;flex-direction:column;width:100%}.titleHoverEffect_uGSyF:hover{-webkit-text-decoration:none!important;text-decoration:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_foHZY",
	"titleHoverEffect": "titleHoverEffect_uGSyF"
};
module.exports = ___CSS_LOADER_EXPORT___;
