
import EntityCard from "~/components/EntityCard/components/EntityCard.vue";
import PersonLink from "~/components/EntityLink/components/PersonLink.vue";

export default {
  name: "SearchResultCardPerson",

  components: {
    EntityCard,
    PersonLink,
  },

  inheritAttrs: false,

  props: {
    /**
     * name
     */
    name: {
      type: String,
      default: "",
    },

    /**
     * id
     */
    id: {
      type: String,
      default: "",
    },

    /**
     * interface: [{key, value}]
     */
    keyFigures: {
      type: Object,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{key, value}]
     */
    keyFigureLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      hover: false,
    };
  },
};
